/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@font-face {
  font-family: 'PT Root UI';
  src: url('/assets/fonts/PT-Root-UI/PT-Root-UI_Regular.woff2') format('woff2'), url('/assets/fonts/PT-Root-UI/PT-Root-UI_Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  font-family: 'PT Root UI', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #304A5C !important;
}
.ant-tree {
  background-color: transparent!important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-pagination {
  display: flex!important;
  align-items: center;
}
.ant-pagination-item-link {
  display: flex!important;
  align-items: center;
  justify-content: center;
}
svg {
  display: inline-block;
  fill: currentColor;
  stroke: currentColor;
  vertical-align: middle;
  height: auto;
  user-select: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  color: #304A5C !important;
}
h1 {
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
}
h2 {
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
}
h3 {
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
}
h4 {
  font-size: 1em;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
}
h5 {
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
}
h6 {
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
}
html,
body,
#root,
#root > .ant-layout,
#root > div > .ant-layout {
  min-height: 100vh;
  background-color: #E5E5E5;
}
.colorWeak {
  filter: invert(80%);
}
.ant-table-tbody .ant-table-cell {
  cursor: pointer;
  user-select: none;
}
.ant-layout {
  min-height: 0;
}
canvas {
  display: block;
}
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ant-tabs.ant-tabs-top {
  min-width: 0;
}
ul,
ol {
  list-style: none;
}
#root .ant-input-affix-wrapper,
.ant-modal-root .ant-input-affix-wrapper,
#root .ant-select-selector,
.ant-modal-root .ant-select-selector,
#root textarea,
.ant-modal-root textarea,
#root .ant-input,
.ant-modal-root .ant-input,
#root .ant-picker,
.ant-modal-root .ant-picker,
#root .ant-select,
.ant-modal-root .ant-select,
#root .ant-input-number,
.ant-modal-root .ant-input-number {
  border-radius: 4px;
}
#root .ant-input-prefix,
.ant-modal-root .ant-input-prefix {
  color: #94A6B5;
}
#root .ant-select-selector,
.ant-modal-root .ant-select-selector {
  min-height: 32px;
  border-radius: 4px !important;
}
#root .ant-input-group-wrapper .ant-input,
.ant-modal-root .ant-input-group-wrapper .ant-input {
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
#root .ant-input-group-wrapper .ant-input-group-addon,
.ant-modal-root .ant-input-group-wrapper .ant-input-group-addon {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
#root button,
.ant-modal-root button {
  border-radius: 4px;
}
#root button.ant-switch,
.ant-modal-root button.ant-switch {
  border-radius: 100px;
}
.ant-btn {
  border-radius: 0.25rem !important;
}
.ant-menu-submenu-title,
.ant-menu-item {
  padding-left: 1rem!important;
}
.ant-menu-sub.ant-menu-inline {
  background-color: transparent!important;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0!important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-menu-inline-collapsed .menuIcon {
  margin: 0;
}
.ant-tabs-tab .ant-tabs-tab-btn {
  width: 165px;
}
@media screen and (max-width: 1024px) {
  .ant-tabs-tab .ant-tabs-tab-btn {
    width: unset;
  }
}
/*.ant-table-filter-trigger-container{
  right: unset!important;
  left: 4rem;
}*/
.ant-layout-header {
  padding: 0 1.5rem !important;
}
.ant-tree-treenode.ant-tree-treenode-switcher-close.ant-tree-treenode-leaf-last,
.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-close,
.ant-tree-treenode.ant-tree-treenode-switcher-open.ant-tree-treenode-leaf-last,
.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-open {
  width: 100%;
}
.ant-table-tbody tr:hover {
  background-color: #F9F9F9;
  transition: 100ms;
}
.ant-layout-sider-trigger {
  padding-left: 1rem;
  border-top: 1px solid #DAE0E6;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ant-tabs .ant-tabs-nav-list {
  margin-left: 1.5rem !important;
}
.ant-tabs .ant-tabs-content {
  overflow-y: auto;
  background: #E5E5E5;
  height: 100%;
}
.ant-tabs .ant-tabs-nav {
  margin-bottom: 0!important;
}
.ant-tabs .ant-card-body {
  padding: 0;
}
.ant-tabs .ant-pro-table {
  margin: 1.5rem;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.ant-tabs .ant-card {
  border-radius: 4px;
}
.ant-tabs-tab-btn {
  white-space: initial;
}
.ant-tabs .ant-pro-table-list-toolbar-container {
  padding: 0.5rem 1.5rem;
}
.ant-tabs-tab .ant-tabs-tab-btn {
  font-weight: bold!important;
  font-size: 16px!important;
  color: #304A5C;
  text-align: center;
}
.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: bold!important;
  font-size: 16px!important;
}
.ant-layout-sider-collapsed .ant-layout-sider-trigger {
  justify-content: center;
  padding-left: 0;
}
.formWrapper .ant-input {
  border: 1px solid #B7C2CC !important;
  border-radius: 4px!important;
}
.ant-input-affix-wrapper.ant-input-password {
  padding: 0;
}
.ant-input-affix-wrapper.ant-input-password input.ant-input {
  padding: 4px 11px;
}
.ant-input-affix-wrapper.ant-input-password .ant-input-suffix {
  margin-right: 4px;
}
.invalid .ant-select-selector,
.invalid.ant-input,
.invalid.ant-input-affix-wrapper,
.invalid.ant-input-password,
.invalid.ant-input-number,
.invalid.ant-picker,
.invalid.ant-picker-focused,
.invalidBordered.ant-input-number-group-wrapper {
  border: 1px solid;
  border-radius: 4px;
  border-color: #ff4d4f !important;
  box-shadow: none;
}
.invalid .ant-select-selector:hover,
.invalid.ant-input:hover,
.invalid.ant-input-affix-wrapper:hover,
.invalid.ant-input-password:hover,
.invalid.ant-input-number:hover,
.invalid.ant-picker:hover,
.invalid.ant-picker-focused:hover,
.invalidBordered.ant-input-number-group-wrapper:hover,
.invalid .ant-select-selector:focus,
.invalid.ant-input:focus,
.invalid.ant-input-affix-wrapper:focus,
.invalid.ant-input-password:focus,
.invalid.ant-input-number:focus,
.invalid.ant-picker:focus,
.invalid.ant-picker-focused:focus,
.invalidBordered.ant-input-number-group-wrapper:focus,
.invalid .ant-select-selector:active,
.invalid.ant-input:active,
.invalid.ant-input-affix-wrapper:active,
.invalid.ant-input-password:active,
.invalid.ant-input-number:active,
.invalid.ant-picker:active,
.invalid.ant-picker-focused:active,
.invalidBordered.ant-input-number-group-wrapper:active {
  border-color: #ff4d4f !important;
  box-shadow: none;
}
.changed .ant-select-selector,
.changed.ant-input,
.changed.ant-input-number,
.changed.ant-picker,
.changed.ant-switch,
.changed.ant-checkbox-wrapper .ant-checkbox,
.changed.ant-input-password,
.changed.ant-picker-focused,
.changed.ant-input-affix-wrapper,
.changedBordered.ant-input-number-group-wrapper {
  box-shadow: 0px 0px 6px #304A5C;
}
.changed .ant-select-selector:hover,
.changed.ant-input:hover,
.changed.ant-input-number:hover,
.changed.ant-picker:hover,
.changed.ant-switch:hover,
.changed.ant-checkbox-wrapper .ant-checkbox:hover,
.changed.ant-input-password:hover,
.changed.ant-picker-focused:hover,
.changed.ant-input-affix-wrapper:hover,
.changedBordered.ant-input-number-group-wrapper:hover,
.changed .ant-select-selector:focus,
.changed.ant-input:focus,
.changed.ant-input-number:focus,
.changed.ant-picker:focus,
.changed.ant-switch:focus,
.changed.ant-checkbox-wrapper .ant-checkbox:focus,
.changed.ant-input-password:focus,
.changed.ant-picker-focused:focus,
.changed.ant-input-affix-wrapper:focus,
.changedBordered.ant-input-number-group-wrapper:focus,
.changed .ant-select-selector:active,
.changed.ant-input:active,
.changed.ant-input-number:active,
.changed.ant-picker:active,
.changed.ant-switch:active,
.changed.ant-checkbox-wrapper .ant-checkbox:active,
.changed.ant-input-password:active,
.changed.ant-picker-focused:active,
.changed.ant-input-affix-wrapper:active,
.changedBordered.ant-input-number-group-wrapper:active {
  box-shadow: 0px 0px 6px #304A5C;
}
.ant-message .anticon {
  top: -2px !important;
}
@media (max-width: 480px) {
  .ant-table {
    width: 100%;
    overflow-x: auto;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > th,
  .ant-table-thead > tr > td,
  .ant-table-tbody > tr > td {
    white-space: pre;
  }
  .ant-table-thead > tr > th > span,
  .ant-table-tbody > tr > th > span,
  .ant-table-thead > tr > td > span,
  .ant-table-tbody > tr > td > span {
    display: block;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body .ant-design-pro > .ant-layout {
    min-height: 100vh;
  }
}
.ant-menu-submenu-selected .ant-menu-item-icon .fa-secondary {
  fill: #00A7EE;
  opacity: 0.4;
}
.ant-menu-submenu-selected .ant-menu-item-icon .fa-primary {
  fill: #00A7EE;
}
.ant-menu.ant-menu-inline-collapsed .subMenu.ant-menu-submenu .ant-menu-submenu-title {
  left: 0.4rem;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item.menuItem > .ant-menu-title-content {
  position: absolute;
}
.ant-select-item-option.displayNone {
  display: none;
}
.ant-input-number {
  width: 100% !important;
}
.ant-select .ant-select-clear {
  top: 43%;
}
.ant-menu-vertical.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu-vertical.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span {
  display: none;
}
.ant-menu.ant-menu-sub.ant-menu-vertical .ant-menu-item.menu-item {
  display: flex;
}
