.overlay {
    background: #e9e9e9;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    opacity: 0.5;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
}