.customTabs {
    flex-grow: 1;
}

.cardTitle {
    margin: 0 !important;
}

.modal {
    max-width: 408px !important;
}

.menuItem {
    min-width: 10.5rem;
    text-align: center;
    margin: 0 !important;
}

.pageWrapper {
    display: flex;
    background-color: #e5e5e5;
    width: 100%;
    height: 100%;
}

.contentHeader {
    height: 3rem;
    width: 100%;
    background-color: white;
}

.sidebar {
    width: 32rem;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.sidebarCloseBlock {
    display: flex;
    justify-content: flex-end;
    margin: 0.5rem 0.5rem 0 0;
}

.main {
    padding: 1.5rem 1.5rem;
    height: calc(100vh - 8.5rem);
    overflow-y: scroll;
}

.menu {
    width: 100%;
    background-color: white;
}

.pageWrapperWithBackground {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgb(229, 229, 229);
    justify-content: space-between;
}

.createMap {
    height: 32px;
    width: 72px;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1.5rem;
    margin-bottom: 1rem;
}

