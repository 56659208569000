.connectionSource {
    line-height: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    height: 48px;
}

.label {
    font-weight: bold;
    padding-right: 5px;
}

.source {
    word-break: break-all;
}

@media screen and (max-width: 900px) {
    .connectionSource {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        line-height: 14px;
        font-size: 12px;
    }
    .label {
        display: block;
    }
}