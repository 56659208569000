@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&family=Roboto:wght@400;500;700&display=swap');

@font-face {
  font-family: 'PTRootUI';
  src:
          url('./PT-Root-UI/PT-Root-UI_Regular.woff2') format('woff2'),
          url('./PT-Root-UI/PT-Root-UI_Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'PTRootUI';
  src:
          url('./PT-Root-UI/PT-Root-UI_Medium.woff2') format('woff2'),
          url('./PT-Root-UI/PT-Root-UI_Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'PTRootUI';
  src:
          url('./PT-Root-UI/PT-Root-UI_Bold.woff2') format('woff2'),
          url('./PT-Root-UI/PT-Root-UI_Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}