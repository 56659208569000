.message {
    min-width: 20rem;
    display: inline-block;
    text-align: left;
    margin-left: 1rem;

    bottom: 20px;
    top: initial;
}

:global(.ant-message) {
    bottom: 20px;
    top: initial;
}
