.ant-menu-submenu.ant-menu-submenu-active .ant-menu-submenu-title,
.ant-menu-submenu.ant-menu-submenu-selected .ant-menu-submenu-title,
.ant-menu-item.ant-menu-item-active,
.ant-menu-item.ant-menu-item-selected {
    >.menuIcon {
        svg {
            .fill-primary,
            .fill-secondary {
                fill: #00a7ee;
            }
        }
    }
}
