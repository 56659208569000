.formSwitch {
    flex-direction: row !important;
    justify-content: flex-start;
    align-items: center;
}

.month-picker .ant-picker-year-btn,
.month-picker .ant-picker-header-super-prev-btn,
.month-picker .ant-picker-header-super-next-btn {
    display: none !important;
}

input.ant-input.ant-input-disabled,
.ant-input-number.ant-input-number-disabled input.ant-input-number-input,

.ant-picker .ant-picker-input > input[disabled],
.ant-select.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    color: black;
    cursor: default;
}

