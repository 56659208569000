@import 'App/pages/page-styles.module';

@media screen and (max-width: 1200px){
  html {
    font-size: 60%;
  }
}

@media screen and (max-width: 1000px){
  html {
    font-size: 40%;
  }
}
