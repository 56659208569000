.contentWrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: rgb(229, 229, 229);
  justify-content: space-between;
}

.contentHeader {
  height: 3rem;
  width: 100%;
  background-color: white;
}
