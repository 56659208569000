.container {
    background-color: white;
    padding: 24px;
    width: 400px;
    box-shadow: 0 1px 4px rgba(30, 50, 92, 0.1);
    border-radius: 8px;

    @media screen and (max-width: 900px) {
        width: 300px;
    }
}

.header {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: black !important;
}

.submitBtn {
    width: 100%;
    background-color: #00a7ee;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
}

.formFooter {
    display: flex;
    justify-content: space-between;
    grid-gap: 24px;
}

.fieldsContainer {
    display: flex;
    flex-direction: column;
    grid-gap: 18px;
}

.password {
    padding: 0;

    :global(input.ant-input) {
        padding: 4px 11px;
    }
    :global(.ant-input-suffix) {
        position: absolute;
        right: 8px;
        top: 8px;
    }
}
