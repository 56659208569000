.rowDragging {
    background: #fafafa;
    outline: 1px solid #ccc;
    z-index: 1;
}

.rowDragging :global(.drag-visible),
:global(.row-dragging) :global(.drag-visible),
:global(.row-dragging) .dragVisible,
.rowDragging .dragVisible {
    visibility: visible;
}

.menuIcon {
    cursor: grab;
    color: #47C8FF;
}

.tableWrapper {
    padding-top: 3rem;
}