.currentTime {
    font-size: 15px;
    color: #748586;
    margin-right: 30px;
}

@media screen and (max-width: 840px) {
    .currentTime {
        display: none;
    }
}
