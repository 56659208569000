.container {
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0;
}

.readOnly {
    justify-content: flex-end;

    &:global(.flex-start) {
        justify-content: flex-start;
    }
}
