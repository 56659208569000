.search {
    margin-bottom: 0.5rem;
}

.wrapper {
    padding: 0.5rem;
}

.wrapperCheckbox {
    padding: 0.5rem;
}

.checkboxBlock {
    max-height: 15rem !important;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}

.checkboxBlock::-webkit-scrollbar {
    display: none;
}

.btnBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.button {
    width: 88px;
    height: 29px;
}

.reset {
    color: #b7c2cc;
    padding-left: 0;
    padding-right: 0;
}

.site-input-group-wrapper {
    margin-bottom: 0.5rem;
}

.site-input-group-wrapper .site-input-left {
    width: 100px !important;
    text-align: center;
}

.site-input-group-wrapper .site-input-split {
    background-color: #fff;
    width: 35px !important;
    border-left: 0;
    border-right: 0;
    pointer-events: none;
}

.site-input-group-wrapper .site-input-right {
    border-left-width: 0;
    width: 100px !important;
    text-align: center;
}

.site-input-group-wrapper .site-input-right:hover,
.site-input-group-wrapper .site-input-right:focus {
    border-left-width: 1px;
}
