.container {
    margin: 0;
    margin-right: 1rem;
    display: flex;
    justify-content: space-between;
}

.required {
    vertical-align: top;
    color: red;
}